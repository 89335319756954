/*
  XS     SM     MD     LG     XL
  <576   576    768    992   1200
min- increasing
max- decreasing
*/

/*----------------------------- min-        */

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
  .gmap .modal-lg,
  .gmap .modal-xl {
    max-width: 950px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
  .gmap .modal-xl {
    max-width: 1200px;
  }
}

/*----------------------------- max-        */

@media (max-width: 575px) {
  .app-dashboard {
    padding: 0.5rem;
  }
}

@media (max-width: 414px) {
  .main .container-fluid {
    padding: 2px;
  }
  .fa-lg {
    font-size: 1em;
  }
  .card-header {
    padding: 0.25rem;
  }
  .card-body {
    padding: 0.25rem;
  }
  .crit-header-panel {
    margin: 3px;
  }
  .gm-style .gm-style-iw {
    padding: 7px;
    max-width: 380px !important;
  }
  .gm-style .gm-style-iw-d {
    max-width: 380px !important;
  }
  /*.double-popup {
     width: 330px; 
  }*/
  .popup {
    padding: 2px;
  }
  .popup h5 {
    font-size: 0.75rem;
    font-weight: bold;
  }
  .popup-header-time {
    font-size: 0.75rem;
  }
  .popup-header-title {
    font-size: 0.75rem;
    font-weight: bold;
  }
  .popup p {
    margin-bottom: 3px;
  }
  .popup-info {
    font-size: 0.75rem;
    /* font-weight: bold; */
  }
  .map-element .error-panel-text {
    font-size: 0.9rem;
  }
  .dispo-list-row {
    max-width: 250px;
  }
}

@media (max-width: 330px) {
  /*.double-popup {
     width: 250px; 
  }*/
  .map-info {
    .result {
      font-size: 0.8rem;
    }
  }
  .dispo-list-row {
    font-size: 0.8rem;
    max-width: 200px;
  }
}
