// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
//@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import '../scss/style.scss';

@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Roboto');

body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr.hr-mapper {
  margin-top: -0.2rem;
  margin-bottom: 0;
  color: white;
}
iframe {
  border: 1px solid #ccc;
}
.bordered {
  border: 1px solid black;
}
.center {
  text-align: center;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.alink {
  outline: none;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-row-start-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ok-btn {
  width: 70px;
}
.info-list {
  font-size: 12px;
}
.dispo-list-row {
  max-width: auto;
}

/* Table ======================================================================================*/
.table-container {
  overflow: scroll;
  height: 730px;
  width: 100%;
}
.table-container-in-map {
  overflow-y: auto;
  max-height: 550px;
  width: 100%;
}
th {
  position: sticky;
  top: -1px;
}
.row-header {
  background-color: #696969;
  color: #fff;
}
.clickable {
  cursor: pointer;
  color: #20a8d8;
}
.clickable:hover {
  color: #167495;
  text-decoration: underline;
}
.nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.width-hour {
  width: 100px !important;
  text-align: center;
}
.width-name {
  max-width: 200px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.width-last {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.width-plag {
  max-width: 150px !important;
  text-align: center;
}

/* Import Panel ==============================================================================*/
.import-title {
  color: #666;
}
.import-subtitle {
  color: #20a8d8;
}
.import-panel {
  position: relative;
  /*height: 200px;*/
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
}
.import-content {
  height: 75%;
}
.import-result-panel {
  margin-top: 20px;
}
.flex-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.import-result {
  color: #ddd;
  background-color: #454545;
  border-radius: 10px;
  padding: 10px;

  .list {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 20px;
    font-size: 90%;
    font-style: italic;
  }
}

/* DayPicker Component =================================================================*/
.day-picker .form-control {
  color: rgb(228, 27, 27);
  font-weight: bold;
  border-radius: 0;
}
.day-picker .form-control:focus {
  background-color: #20a8d8;
  color: white;
}
.day-picker .picker-date {
  width: 100px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0px;
}
.day-picker .picker-time {
  width: 50px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  margin-left: -1px;
}
.day-picker-simple {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
/* Forms  ============================================================================*/
.update-form .form-group {
  margin-bottom: 0rem;
}
.update-form .form-group label {
  margin-bottom: 0;
  font-style: italic;
  font-size: 0.8rem;
  color: #999;
}
.form-check-label {
  color: #444 !important; /* #5c6873 */
  font-style: normal !important;
  font-size: 0.8rem;
}
.update-form input {
  font-weight: 700;
}
.update-form .card-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #c8ced3;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.update-form .card-footer > :not(:last-child) {
  margin-right: 0.25rem;
}
.comp-ad {
  background-color: rgb(238, 177, 238);
  font-size: 0.7rem !important;
  padding: 3px 2px 2px 2px;
  width: 35px;
  border-radius: 5px;
  border: 1px solid #777;
  display: inline-block;
  text-align: center;
}
.comp-advf {
  background-color: rgb(250, 250, 120);
  font-size: 0.7rem !important;
  padding: 3px 2px 2px 2px;
  width: 35px;
  border-radius: 5px;
  border: 1px solid #777;
  display: inline-block;
  text-align: center;
}
.comp-aes {
  background-color: rgb(90, 250, 90);
  font-size: 0.7rem !important;
  padding: 3px 2px 2px 2px;
  width: 35px;
  border-radius: 5px;
  border: 1px solid #777;
  display: inline-block;
  text-align: center;
}
.comp-all {
  font-size: 0.7rem !important;
}
.comp-smaller {
  font-size: 0.5rem !important;
  padding: 2px 0 1px 0;
  width: 30px;
}
.transport-radio .comp-all {
  padding-top: 4px;
}
.ml-n0 {
  margin-left: -0.5rem !important;
}
.radio.form-check-inline {
  margin-right: 0.5rem;
}
.radio-label {
  font-size: 0.8rem;
}
.radio-img-container {
  height: 23px;
}
.radio-disabled {
  opacity: 0.3;
}

/* Errors  =======================================================================================*/
.error-panel-text {
  color: rgb(190, 27, 27);
  font-weight: bold;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  height: 60px;
  font-size: 1rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error-panel-auth {
  background-color: #ffffffaa;
}
.error-panel {
  background-color: transparent;
  margin-bottom: 1rem;
}
.error-message {
  color: rgb(190, 27, 27);
  font-style: italic;
  font-weight: bold;
  font-size: 0.8rem;
}
.error-title-message {
  color: rgb(190, 27, 27);
  font-style: italic;
  font-weight: bold;
}
.error-input-text {
  padding: 0;
  color: rgb(190, 27, 27);
  font-style: italic;
  font-size: 0.8rem;
  min-height: 1.2rem;
}
.error-input-text-center {
  padding: 0;
  color: rgb(190, 27, 27);
  font-style: italic;
  font-size: 0.8rem;
  text-align: center;
  min-height: 1.2rem;
}
.error-mb {
  margin: 0 0 0.5rem 0;
}
.error-input {
  border: 1px solid rgb(190, 27, 27);
  border-radius: 0.25rem;
}

/* Layout override  ============================================================================*/
.main .container-fluid {
  padding: 0.5rem;
}
.hide {
  display: none;
}
.breadcrumb {
  margin-bottom: 0;
}
.sidebar .nav {
  width: 220px;
}
.sidebar .nav-title {
  font-size: 110%;
  color: #20a8d8;
}
.nav-link {
  padding: 0;
  display: inline;
}
.avatar > img,
.img-avatar,
.img-circle {
  border-radius: 20px;
  width: 40px !important;
  margin: 3px 0 0 0;
}
.img-header {
  height: 40px !important;
}
.welcome-header {
  font-size: 1.2rem;
}
.welcome-header-user {
  font-size: 1.4rem;
  font-weight: bold;
}
.app-sign {
  height: 100vh;
  background: url('../assets/img/bgimg1.jpg') center center no-repeat;
  background-size: cover;
}
.app-home {
  margin: -0.5rem;
  padding: 0.5rem;
  height: 100%;
  background: url('../assets/img/bgimg1.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat;
}
.app-footer {
  flex: 0 0 25px;
  border: none;
  background-color: inherit;
}
.app-dashboard {
  margin: -0.5rem;
  padding: 2rem;
  height: 100%;
  background: url('../assets/img/bgimg1.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat;
}

/* Map divers  ====================================================================================*/
.overlay {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}
.opaque-text {
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: transparent;
  color: #fff;
  font-size: 32px;
  font-weight: 600;
}
.import-feedback {
  text-align: center;
  margin: 1rem auto;
  background-color: white;
  width: 80%;
  max-width: 300px;
  border: 1px solid #888;
}
.search-crit {
  font-size: 0.75rem;
  color: #666;
}
.telephoneLink {
  color: #222;
}
.telephoneLink:hover {
  text-decoration: none;
  color: #222;
}
.crit-header-panel {
  background-color: white;
  margin: 10px;
  padding: 5px;
  border: 1px solid #aaa;
}
.map-element {
  padding-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.map-nodata {
  text-align: center;
  color: #ccc;
  h6 {
    font-weight: bold;
    color: #c72;
  }
}
.map-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  font-size: 13px;
  border-bottom: 1px solid #ccc;
  background-color: white;

  .result {
    margin-left: 5px;
    color: rgb(190, 27, 27);
    font-weight: bold;
  }
  .toggle-name {
    cursor: pointer;
    background-color: #ccc;
    border: 1px solid #333;
    padding: 0 10px;
    border-radius: 3px;
  }
  .zoom {
    margin-right: 5px;
    font-size: 12px;
  }
}

/* Map Popup (buildInfo)  =========================================================================*/
.popup-wrapper {
  width: 265px;
  height: 191px;
}
.popup-wrapper-plan {
  width: 100%;
  height: 100%;
}
.double-popup {
  display: flex;
  flex-direction: row;
}
.popup {
  font-family: 'Open Sans';
  border: none;
  padding: 10px;
  width: 100%;
}
.popup-rounded {
  border: 2px solid #333;
  border-radius: 5px;
}
.popup-1 {
  color: black;
  /* background-color: #b89877; */
  background-color: #ec9d78;
}
.popup-2 {
  color: black;
  /* background-color: #6d93db; */
  background-color: #6897ee;
}
.popup-info {
  font-size: 0.85rem;
}
.popup-header {
  padding: 0;
  margin-bottom: 5px;
  font-size: 0.9rem;
  min-height: 32px;
}
.popup-header-title {
  color: #e9fbfd;
  font-weight: bold;
  font-size: 0.9rem;
}
.popup p {
  margin-bottom: 10px;
}
.popup-header-time {
  background-color: #333;
  color: #eee;
  font-weight: bold;
  font-size: 1.1rem;
  margin: 0;
  padding: 5px;
}
.popup-emp-icon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.popup-emp-icon img {
  width: 19px;
  height: 19px;
}

/* MARKER =====================================================================================*/
/*.gm-style {
  font-weight: 700;
}*/
/* marker's title
.gm-style > div > div > div > div > div > div > div > div > div {
  color: gray;
}*/
.gm-style .gm-style-iw {
  background-color: #ffffff;
  opacity: 0.9;
  scrollbar-width: 0;
  padding: 12px;
}
.cluster-marker {
  color: #fff;
  background: #0f60da;
  opacity: 0.7;
  border-radius: 50%;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  position: relative;
  z-index: 200;
  cursor: pointer;
}
/************/
.marker-plan {
  position: absolute;
  top: -40px;
  left: -20px;
  width: 350px;
  text-align: left;
  border: none;
  background: none;
  z-index: 190;
}
.marker-plan img.pin-icon {
  width: 40px;
  cursor: pointer;
  z-index: 180;
}
.marker-plan img.pin-icon.anim {
  animation: jump 1s linear;
  animation-iteration-count: infinite;
}
.marker-plan .dark {
  opacity: 0.7;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  padding: 2px;
  border: 1px solid white;
  border-radius: 5px;
  color: white !important;
  background-color: #444;
  cursor: pointer;
  z-index: 180;
}
/************/
.marker-sector {
  position: relative;
  z-index: -1;
  width: 220px;
  text-align: left;
  color: #444 !important;
  font-size: 13px;
  font-weight: bold;
}
.marker-sector-emp {
  width: 25px;
  height: 25px;
}
.marker-sector-cust {
  width: 40px;
  height: 40px;
}

@keyframes jump {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
